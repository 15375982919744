<template>
    <div class="product">
        <products-id></products-id>
        <div class="new-products">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="new-products-title-box"><h1>Productos relacionados</h1></div>
                    </div>
                </div>
            </div>
        </div>
        <target-products></target-products>
        <benefits></benefits>
    </div>
</template>
<script>

    import ProductsId from '/src/components/ProductsId'
    import TargetProducts from '/src/components/TargetProducts'
    import Benefits from '/src/components/Benefits'

    export default {
        name: 'Product',
        components: {
            ProductsId,
            TargetProducts,
            Benefits,
        }
    }

</script>
