import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import ContactDetails from '../views/ContactDetails.vue'
import SuccessfulPayment from '../views/SuccessfulPayment.vue'
import Preparations from '../views/Preparations.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/producto',
    name: 'Product',
    component: Product
  },
  {
    path: '/contacto',
    name: 'ContactDetails',
    component: ContactDetails
  },
  {
    path: '/compra-exitosa',
    name: 'SuccessfulPayment',
    component: SuccessfulPayment
  },
  {
    path: '/arreglos',
    name: 'Preparations',
    component: Preparations
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  { path: '/home', redirect: '/' }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  // ...
});
export default router
