<template>
    <div class="contact">
        <div class="container">
            <div class="row">
                <div class="col-xl-8">
                    <div class="contact-picture">
                        <form>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div v-if="currentTabStatus === 'step0'">
                                        <div class="step1">
                                            <div class="row">
                                                <div class="col-xl-3"></div>
                                                <div class="col-xl-6">
                                                    <div>
                                                        <div class="steps-image-box">
                                                            <img src="@/assets/images/step-1.png" alt=""
                                                                 class="steps-images">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3"></div>
                                                <div class="col-xl-12">
                                                    <div class="contact-information-data-box">
                                                        <h3>Información de contacto</h3>
                                                    </div>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <div>
                                                                    <div class="form-group">
                                                                        <label class="label-input"
                                                                               for="nombre">Nombre</label>
                                                                        <input type="text"
                                                                               class="form-control steps-input-style"
                                                                               id="nombre" aria-describedby="emailHelp"
                                                                               placeholder="Nombre">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <div>
                                                                    <div class="form-group">
                                                                        <label class="label-input"
                                                                               for="apellido">Apellido</label>
                                                                        <input type="text"
                                                                               class="form-control steps-input-style"
                                                                               id="apellido"
                                                                               aria-describedby="emailHelp"
                                                                               placeholder="Apellido">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <div>
                                                                    <div class="form-group">
                                                                        <label class="label-input" for="dni">DNI</label>
                                                                        <input type="text"
                                                                               class="form-control steps-input-style"
                                                                               id="dni"
                                                                               aria-describedby="emailHelp"
                                                                               placeholder="DNI">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <div>
                                                                    <div class="form-group">
                                                                        <label class="label-input"
                                                                               for="telefono">Teléfono</label>
                                                                        <input type="text"
                                                                               class="form-control steps-input-style"
                                                                               id="telefono"
                                                                               aria-describedby="emailHelp"
                                                                               placeholder="Teléfono">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-12">
                                                                <div>
                                                                    <div class="form-group">
                                                                        <label class="label-input"
                                                                               for="correo">Correo</label>
                                                                        <input type="text"
                                                                               class="form-control steps-input-style"
                                                                               id="correo"
                                                                               aria-describedby="emailHelp"
                                                                               placeholder="Correo">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="currentTabStatus === 'step1'">
                                        <div class="step2">
                                            <div class="row">
                                                <div class="col-xl-3"></div>
                                                <div class="col-xl-6">
                                                    <div>
                                                        <div class="steps-image-box">
                                                            <img src="@/assets/images/step-2.png" alt=""
                                                                 class="steps-images">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3"></div>
                                                <div class="col-xl-12">
                                                    <div class="contact-information-data-box">
                                                        <h3>Tus direcciones</h3>
                                                    </div>
                                                    <div>
                                                        <div class="form-check-style">
                                                            <div class="form-direction-box">
                                                                <input class="form-check-input" type="radio"
                                                                       name="" id="direction1" style="display: none;">
                                                                <p class="form-direction-title">CASA 1</p>
                                                                <p class="form-direction-text">Avenida Manuel Rodríguez
                                                                    635 - San isidro</p>
                                                            </div>
                                                            <div class="form-check-btn-box">
                                                                <label for="direction1"
                                                                       class="btn btn-danger btn-pink2">Usar dirección
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="contact-information-data-box">
                                                        <h3>Nueva dirección</h3>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4">
                                                            <div>
                                                                <div class="form-group">
                                                                    <label for="departamento" class="label-input">Departamento</label>
                                                                    <select id="departamento"
                                                                            class="form-control label-input-select">
                                                                        <option :value="null">Departamento</option>
                                                                        <option value="">1</option>
                                                                        <option value="">2</option>
                                                                        <option value="">3</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4">
                                                            <div>
                                                                <div class="form-group">
                                                                    <label for="provincia"
                                                                           class="label-input">Distrito</label>
                                                                    <select id="provincia"
                                                                            class="form-control label-input-select">
                                                                        <option :value="null">Provincia</option>
                                                                        <option value="">1</option>
                                                                        <option value="">2</option>
                                                                        <option value="">3</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4">
                                                            <div>
                                                                <div class="form-group">
                                                                    <label for="distrito"
                                                                           class="label-input">Provincia</label>
                                                                    <input type="text" id="distrito"
                                                                           aria-describedby="emailHelp"
                                                                           placeholder="Distrito"
                                                                           class="form-control steps-input-style">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-12">
                                                            <div>
                                                                <div class="form-group">
                                                                    <label for="dirección"
                                                                           class="label-input">Dirección</label>
                                                                    <input type="text" id="dirección"
                                                                           aria-describedby="emailHelp"
                                                                           placeholder="Dirección"
                                                                           class="form-control steps-input-style">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-12">
                                                            <div>
                                                                <div class="form-group">
                                                                    <label for="referencia"
                                                                           class="label-input">Referencia</label>
                                                                    <input type="text" id="referencia"
                                                                           aria-describedby="emailHelp"
                                                                           placeholder="Referencia"
                                                                           class="form-control steps-input-style">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="currentTabStatus === 'step2'">
                                        <div class="step3">
                                            <div class="row">
                                                <div class="col-xl-3"></div>
                                                <div class="col-xl-6">
                                                    <div>
                                                        <div class="steps-image-box">
                                                            <img src="@/assets/images/step-3.png" alt=""
                                                                 class="steps-images">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3"></div>
                                                <div class="col-xl-12">
                                                    <div class="contact-information-data-box">
                                                        <h3>Selecciona tipo de delivery</h3>
                                                    </div>
                                                    <div>
                                                        <div class="form-group">
                                                            <input class="form-check-input" type="radio"
                                                                   name="delivery" id="delivery" style="display:none;">
                                                            <label class="form-check-style2" for="delivery">
                                                                <div class="form-check-delivery-box">
                                                                    <div>
                                                                        <p class="form-direction-title">Delivery
                                                                            express</p>
                                                                        <p class="form-direction-text">24 horas - Lima
                                                                            metropolitana y Callao</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div class="form-delivery-price-box">
                                                                        <p>S/ 20.00</p>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="form-group">
                                                            <input class="form-check-input" type="radio"
                                                                   name="delivery" id="delivery1" style="display:none;">
                                                            <label class="form-check-style2" for="delivery1">
                                                                <div class="form-check-delivery-box">
                                                                    <div>
                                                                        <p class="form-direction-title">Delivery
                                                                            regular</p>
                                                                        <p class="form-direction-text">36 horas - Lima
                                                                            metropolitana y Callao</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div class="form-delivery-price-box">
                                                                        <p>S/ 20.00</p>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="currentTabStatus === 'step3'">
                                        <div class="step4">
                                            <div class="row">
                                                <div class="col-xl-3"></div>
                                                <div class="col-xl-6">
                                                    <div>
                                                        <div class="steps-image-box">
                                                            <img src="@/assets/images/step-4.png" alt=""
                                                                 class="steps-images">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3"></div>
                                                <div class="col-xl-12">
                                                    <div class="contact-information-data-box">
                                                        <h3>Método de pago</h3>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="numero-tarjeta"
                                                                   class="label-input">Número de tarjeta</label>
                                                            <input type="text" id="numero-tarjeta"
                                                                   aria-describedby="emailHelp"
                                                                   placeholder="Número de tarjeta"
                                                                   class="form-control steps-input-style">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="vencimiento"
                                                                   class="label-input">Vencimiento</label>
                                                            <input type="text" id="vencimiento"
                                                                   aria-describedby="emailHelp"
                                                                   placeholder="MM/YY"
                                                                   class="form-control steps-input-style">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="CVV"
                                                                   class="label-input">CVV</label>
                                                            <input type="text" id="CVV"
                                                                   aria-describedby="emailHelp"
                                                                   placeholder="CVV"
                                                                   class="form-control steps-input-style">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="titular-tarjeta"
                                                                   class="label-input">Titular de la tarjeta</label>
                                                            <input type="text" id="titular-tarjeta"
                                                                   aria-describedby="emailHelp"
                                                                   placeholder="Titular de la tarjeta"
                                                                   class="form-control steps-input-style">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12">
                                    <div class="steps-btn-box">
                                        <button v-if="numbStep > 0" @click="makeActive('step', numbStep-=1 )"
                                                type="button" class="btn btn-danger btn-steps-previous">Anterior
                                        </button>
                                        <button v-if="numbStep < 3" @click="makeActive('step', numbStep+=1 )" type="button"
                                                class="btn btn-danger btn-steps-next">Siguiente
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div>
                        <div class="shopping-cart">
                            <div class="shopping-cart-title-box">
                                <p class="shopping-cart-title-text">Carrito de compras</p>
                                <div>
                                    <img src="@/assets/images/close.png" alt="">
                                </div>
                            </div>
                            <div class="shopping-cart-product-complete-all">
                                <div class="shopping-cart-image-product-box d-flex">
                                    <div>
                                        <img src="@/assets/images/image-8.png" alt=""
                                             class="shopping-cart-image-product">
                                    </div>
                                    <div class="shopping-cart-space-left">
                                        <div>
                                            <p class="shopping-cart-product-name">Corazón primaveral</p>
                                        </div>
                                        <div class="shopping-cart-center-price-number">
                                            <div style="display:flex;">
                                                <div class="products-id-amount-input-number0" @click="decreaseAmount">-
                                                </div>
                                                <input type="number" id="quantity" :value="OrderQuantify"
                                                       class="products-id-amount-input-number2"
                                                       name="" min="1" max="20" disabled="disabled">
                                                <div class="products-id-amount-input-number1" @click="increaseAmount">+
                                                </div>
                                            </div>
                                            <div class="shopping-cart-product-price-box">
                                                <p class="shopping-cart-product-price">S/ 119.99</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="shopping-cart-trash-box">
                                        <img src="@/assets/images/trash.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="shopping-cart-price-sub-total-box">
                                <div>
                                    <p>Subtotal</p>
                                    <p>Descuentos</p>
                                </div>
                                <div>
                                    <p>S/ 129.99</p>
                                    <p>-S/ 10.00</p>
                                </div>
                            </div>
                            <div class="shopping-cart-line-box">
                                <div class="shopping-cart-line"></div>
                            </div>
                            <div class="shopping-cart-price-total-box">
                                <div>
                                    <p>Total</p>
                                </div>
                                <div>
                                    <p>S/ 119.99</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .products-id-amount-input-number0, .products-id-amount-input-number2, .products-id-amount-input-number1 {
        width: 40px;
        height: 35px;
        font-size: 21px;
    }
</style>
<script>
    export default {
        name: 'StepsContact',

        data() {
            return {
                currentTabStatus: 'step0',
                numbStep: 0,
                OrderQuantify: 1,
            }
        },
        methods: {
            makeActive: function (val, numbStep) {
                console.log(val + numbStep)
                this.currentTabStatus = val + numbStep
            },
            increaseAmount: function () {
                if (this.OrderQuantify === 20) {
                    this.OrderQuantify = 20
                } else {
                    this.OrderQuantify = this.OrderQuantify + 1
                }
            },
            decreaseAmount: function () {
                if (this.OrderQuantify === 1) {
                    this.OrderQuantify = 1
                } else {
                    this.OrderQuantify = this.OrderQuantify - 1
                }
            }
        }
    }
</script>
