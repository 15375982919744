<template>
    <div class="total-cost">
        <div class="container">
            <div class="row">
                <div class="col-xl-2"></div>
                <div class="col-xl-8">
                    <div class="total-cost-all">
                        <div class="total-cost-title-box">
                            <h1>¡Pago exitoso!</h1>
                        </div>
                        <div class="total-cost-title-number">
                            <p>El número de tu orden es:</p>
                        </div>
                        <div class="total-cost-number-box">
                            <p>2353464574575</p>
                        </div>
                        <div class="shopping-cart-line-box">
                            <div class="shopping-cart-line"></div>
                        </div>
                        <div class="total-cost-details-title">
                            <h5>Detalles de tu compra</h5>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-xl-6 col-md-6">
                                <div class="total-cost-buy-check-box">
                                    <div>
                                        <img src="@/assets/images/image-8.png" alt="">
                                    </div>
                                    <div class="total-cost-buy-check-picture">
                                        <p class="total-cost-buy-check-name">Corazón primaveral</p>
                                        <p class="total-cost-buy-check-precio">S/ 119.99</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="total-cost-btn-box">
                                <a href="/" class="btn btn-danger btn-steps-next">Volver a página principal</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'TotalCost',
        data() {
            return {}
        },
        components: {}
    }
</script>
