<template>
    <div class="contact-details">
        <steps-contact></steps-contact>
    </div>
</template>
<script>

    import StepsContact from '/src/components/StepsContact'

    export default {
        name: 'ContactDetails',
        components: {
            StepsContact,
        }
    }
</script>
