<template>
    <section>
        <div class="target-products-box">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-6">
                        <div class="target-products-picture">
                            <div class="target-products-picture-box">
                                <img src="@/assets/images/products1.png" alt="" class="target-products-image">
                                <div class="target-discount-picture">
                                    <div class="target-box-discount">
                                        <p class="target-box-discount-text">-20%</p>
                                    </div>
                                </div>
                                <div class="target-head-picture">
                                    <div class="target-box-favorite-box">
                                        <img src="@/assets/images/corazon-white.png" alt="" class="target-head-image">
                                    </div>
                                </div>
                            </div>
                            <div class="target-products-data-box">
                                <p class="target-products-data-name">Box de 12 rosas y chocolates</p>
                                <p class="target-products-data-price">S/ 119.99</p>
                            </div>
                            <router-link to="/contacto" class="btn btn-danger btn-pink1">
                                <div>
                                    <img src="@/assets/images/trolley-white.png" alt="" class="icon-trolley">
                                </div>
                                Añadir al carrito
                            </router-link>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="target-products-picture">
                            <div class="target-products-picture-box">
                                <img src="@/assets/images/products2.png" alt="" class="target-products-image">
                                <div class="target-discount-picture">
                                    <div class="target-box-discount">
                                        <p class="target-box-discount-text">-20%</p>
                                    </div>
                                </div>
                                <div class="target-head-picture">
                                    <div class="target-box-favorite-box">
                                        <img src="@/assets/images/corazon-red.png" alt="" class="target-head-image">
                                    </div>
                                </div>
                            </div>
                            <div class="target-products-data-box">
                                <p class="target-products-data-name">Canasta de rosas x 6 un.</p>
                                <p class="target-products-data-price">S/ 119.99</p>
                            </div>
                            <router-link to="/contacto" class="btn btn-danger btn-pink1">
                                <div>
                                    <img src="@/assets/images/trolley-white.png" alt="" class="icon-trolley">
                                </div>
                                Añadir al carrito
                            </router-link>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="target-products-picture">
                            <div class="target-products-picture-box">
                                <img src="@/assets/images/products3.png" alt="" class="target-products-image">
                                <div class="target-discount-picture">
                                    <div class="target-box-discount">
                                        <p class="target-box-discount-text">-20%</p>
                                    </div>
                                </div>
                                <div class="target-head-picture">
                                    <div class="target-box-favorite-box">
                                        <img src="@/assets/images/corazon-white.png" alt="" class="target-head-image">
                                    </div>
                                </div>
                            </div>
                            <div class="target-products-data-box">
                                <p class="target-products-data-name">Arreglo de flores</p>
                                <p class="target-products-data-price">S/ 119.99</p>
                            </div>
                            <router-link to="/contacto" class="btn btn-danger btn-pink1">
                                <div>
                                    <img src="@/assets/images/trolley-white.png" alt="" class="icon-trolley">
                                </div>
                                Añadir al carrito
                            </router-link>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="target-products-picture">
                            <div class="target-products-picture-box">
                                <img src="@/assets/images/products4.png" alt="" class="target-products-image">
                                <div class="target-discount-picture">
                                    <div class="target-box-discount">
                                        <p class="target-box-discount-text">-20%</p>
                                    </div>
                                </div>
                                <div class="target-head-picture">
                                    <div class="target-box-favorite-box">
                                        <img src="@/assets/images/corazon-red.png" alt="" class="target-head-image">
                                    </div>
                                </div>
                            </div>
                            <div class="target-products-data-box">
                                <p class="target-products-data-name">Ramo de tulipanes x 12 un.</p>
                                <p class="target-products-data-price">S/ 119.99</p>
                            </div>
                            <router-link to="/contacto" class="btn btn-danger btn-pink1">
                                <div>
                                    <img src="@/assets/images/trolley-white.png" alt="" class="icon-trolley">
                                </div>
                                Añadir al carrito
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

    export default {
        name: 'TargetProducts',
        data() {
            return {}
        },
        components: {}
    }
</script>
