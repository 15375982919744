<template>
    <div id="app">
        <navbar></navbar>
        <categories></categories>
        <!--<div id="nav">
            <router-link to="/">Home</router-link>
            |
            <router-link to ="/about">About</router-link>
        </div>-->
        <router-view/>
        <!--<promotions></promotions>
        <target-products></target-products>-->
        <Footer></Footer>
    </div>
</template>
<script>

    import Navbar from '/src/components/Navbar'
    import Categories from '/src/components/Categories'
    import Footer from '/src/components/Footer'

    export default {
        name: 'App',
        components: {
            Navbar,
            Categories,
            Footer
        }
    }
</script>
