var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"target-products-box"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-md-6"},[_c('div',{staticClass:"target-products-picture"},[_vm._m(0),_vm._m(1),_c('router-link',{staticClass:"btn btn-danger btn-pink1",attrs:{"to":"/contacto"}},[_c('div',[_c('img',{staticClass:"icon-trolley",attrs:{"src":require("@/assets/images/trolley-white.png"),"alt":""}})]),_vm._v(" Añadir al carrito ")])],1)]),_c('div',{staticClass:"col-xl-3 col-md-6"},[_c('div',{staticClass:"target-products-picture"},[_vm._m(2),_vm._m(3),_c('router-link',{staticClass:"btn btn-danger btn-pink1",attrs:{"to":"/contacto"}},[_c('div',[_c('img',{staticClass:"icon-trolley",attrs:{"src":require("@/assets/images/trolley-white.png"),"alt":""}})]),_vm._v(" Añadir al carrito ")])],1)]),_c('div',{staticClass:"col-xl-3 col-md-6"},[_c('div',{staticClass:"target-products-picture"},[_vm._m(4),_vm._m(5),_c('router-link',{staticClass:"btn btn-danger btn-pink1",attrs:{"to":"/contacto"}},[_c('div',[_c('img',{staticClass:"icon-trolley",attrs:{"src":require("@/assets/images/trolley-white.png"),"alt":""}})]),_vm._v(" Añadir al carrito ")])],1)]),_c('div',{staticClass:"col-xl-3 col-md-6"},[_c('div',{staticClass:"target-products-picture"},[_vm._m(6),_vm._m(7),_c('router-link',{staticClass:"btn btn-danger btn-pink1",attrs:{"to":"/contacto"}},[_c('div',[_c('img',{staticClass:"icon-trolley",attrs:{"src":require("@/assets/images/trolley-white.png"),"alt":""}})]),_vm._v(" Añadir al carrito ")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target-products-picture-box"},[_c('img',{staticClass:"target-products-image",attrs:{"src":require("@/assets/images/products1.png"),"alt":""}}),_c('div',{staticClass:"target-discount-picture"},[_c('div',{staticClass:"target-box-discount"},[_c('p',{staticClass:"target-box-discount-text"},[_vm._v("-20%")])])]),_c('div',{staticClass:"target-head-picture"},[_c('div',{staticClass:"target-box-favorite-box"},[_c('img',{staticClass:"target-head-image",attrs:{"src":require("@/assets/images/corazon-white.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target-products-data-box"},[_c('p',{staticClass:"target-products-data-name"},[_vm._v("Box de 12 rosas y chocolates")]),_c('p',{staticClass:"target-products-data-price"},[_vm._v("S/ 119.99")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target-products-picture-box"},[_c('img',{staticClass:"target-products-image",attrs:{"src":require("@/assets/images/products2.png"),"alt":""}}),_c('div',{staticClass:"target-discount-picture"},[_c('div',{staticClass:"target-box-discount"},[_c('p',{staticClass:"target-box-discount-text"},[_vm._v("-20%")])])]),_c('div',{staticClass:"target-head-picture"},[_c('div',{staticClass:"target-box-favorite-box"},[_c('img',{staticClass:"target-head-image",attrs:{"src":require("@/assets/images/corazon-red.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target-products-data-box"},[_c('p',{staticClass:"target-products-data-name"},[_vm._v("Canasta de rosas x 6 un.")]),_c('p',{staticClass:"target-products-data-price"},[_vm._v("S/ 119.99")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target-products-picture-box"},[_c('img',{staticClass:"target-products-image",attrs:{"src":require("@/assets/images/products3.png"),"alt":""}}),_c('div',{staticClass:"target-discount-picture"},[_c('div',{staticClass:"target-box-discount"},[_c('p',{staticClass:"target-box-discount-text"},[_vm._v("-20%")])])]),_c('div',{staticClass:"target-head-picture"},[_c('div',{staticClass:"target-box-favorite-box"},[_c('img',{staticClass:"target-head-image",attrs:{"src":require("@/assets/images/corazon-white.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target-products-data-box"},[_c('p',{staticClass:"target-products-data-name"},[_vm._v("Arreglo de flores")]),_c('p',{staticClass:"target-products-data-price"},[_vm._v("S/ 119.99")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target-products-picture-box"},[_c('img',{staticClass:"target-products-image",attrs:{"src":require("@/assets/images/products4.png"),"alt":""}}),_c('div',{staticClass:"target-discount-picture"},[_c('div',{staticClass:"target-box-discount"},[_c('p',{staticClass:"target-box-discount-text"},[_vm._v("-20%")])])]),_c('div',{staticClass:"target-head-picture"},[_c('div',{staticClass:"target-box-favorite-box"},[_c('img',{staticClass:"target-head-image",attrs:{"src":require("@/assets/images/corazon-red.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target-products-data-box"},[_c('p',{staticClass:"target-products-data-name"},[_vm._v("Ramo de tulipanes x 12 un.")]),_c('p',{staticClass:"target-products-data-price"},[_vm._v("S/ 119.99")])])
}]

export { render, staticRenderFns }