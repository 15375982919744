<template>
    <div class="products-id">
        <div class="container">
            <div class="row">
                <div class="col-xl-1 col-2">
                    <div>
                        <div class="products-id-image-box">
                            <img src="@/assets/images/image-7.png" alt="" class="products-id-image">
                        </div>
                        <div class="products-id-image-box">
                            <img src="@/assets/images/image-9.png" alt="" class="products-id-image">
                        </div>
                        <div class="products-id-image-box">
                            <img src="@/assets/images/image-8.png" alt="" class="products-id-image">
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-10">
                    <div class="products-id-image-great">
                        <div class="products-id-image-picture">
                            <img src="@/assets/images/flowers-image.png" alt="" class="products-id-image">
                        </div>
                        <div class="target-head-picture">
                            <div class="target-box-favorite-box">
                                <img src="@/assets/images/corazon-white.png" alt="" class="target-head-image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="products-id-dates-box">
                        <div class="products-id-categories-picture">
                            <p>Inicio</p>
                            <p class="products-id-paragraph-arrow-right">
                                <i class="fas fa-angle-right products-id-icon-right"></i>
                            </p>
                            <p>Categoría 1</p>
                            <p class="products-id-paragraph-arrow-right">
                                <i class="fas fa-angle-right products-id-icon-right"></i>
                            </p>
                            <p class="step-select-style">Sub categoría 1</p>
                        </div>
                        <div class="products-id-title-product">
                            <h4>Corazón primaveral</h4>
                        </div>
                        <div class="products-id-price-previous-box">
                            <p class="products-id-price-current">S/ 119.99</p>
                            <p class="products-id-price-previous">S/ 200.00</p>
                        </div>
                        <div class="products-id-description-box">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor lobortis mauris potenti
                                auctor. Porttitor etiam sed at tempus platea amet eu nulla. Velit ipsum sociis porta
                                habitant egestas interdum tincidunt habitasse. Morbi id commodo molestie neque sit
                                adipiscing natoque tellus.</p>
                        </div>
                        <div class="products-id-stock-box">
                            <p>Stock: 10</p>
                        </div>
                        <div class="products-id-free-shipping-box">
                            <div class="products-id-free-shipping-image-picture">
                                <img src="@/assets/images/truck.png" alt="">
                            </div>
                            <p class="products-id-free-shipping-text">Envío gratis</p>
                        </div>
                        <div class="products-id-amount-box-all">
                            <div class="products-id-amount-box">
                                <div class="products-amount-picture">
                                    <div class="products-id-amount-input-number0" @click="decreaseAmount">-</div>
                                    <input type="number" id="quantity" :value="OrderQuantify" class="products-id-amount-input-number2"
                                           name="" min="1" max="20" disabled="disabled">
                                    <div class="products-id-amount-input-number1" @click="increaseAmount">+</div>
                                </div>
                                <div>
                                    <router-link to="/contacto" class="btn btn-danger btn-pink1">
                                        <div>
                                            <img src="@/assets/images/trolley-white.png" alt="" class="icon-trolley">
                                        </div>
                                        Añadir al carrito
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ProductsId',
        data() {
            return {
                OrderQuantify: 1,
            }
        },
        methods: {
            increaseAmount: function () {
                if(this.OrderQuantify === 20) {
                    this.OrderQuantify = 20
                } else {
                    this.OrderQuantify = this.OrderQuantify + 1
                }
            },
            decreaseAmount: function () {
                if (this.OrderQuantify === 1) {
                    this.OrderQuantify = 1
                } else {
                    this.OrderQuantify = this.OrderQuantify - 1
                }
            }
        }
    }
</script>
