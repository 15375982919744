<template>
    <div class="products">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 col-md-8 col-8">
                    <div class="products-visualize-box">
                        <router-link to="/producto">
                            <img src="@/assets/images/flowers-image.png" alt="" class="products-visualize-image">
                        </router-link>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 col-4">
                    <div class="products-image-small-box">
                        <div>
                            <router-link to="/producto">
                                <img src="@/assets/images/flowers-image2.png" alt="" class="products-visualize-image2">
                            </router-link>
                            <div class="products-height"></div>
                            <router-link to="/producto">
                                <img src="@/assets/images/flowers-image3.png" alt="" class="products-visualize-image2">
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'Promotions',
        data() {
            return {}
        },
        components: {}
    }
</script>
