<template>
    <div>
        <total-cost></total-cost>
    </div>
</template>
<script>

    import TotalCost from '/src/components/TotalCost'

    export default {
        name: 'SuccessfulPayment',
        data() {
            return {}
        },
        components: {
            TotalCost
        }
    }
</script>
