<template>
    <div class="home">
        <promotions></promotions>
        <div class="new-products">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="new-products-title-box">
                            <h1>Nuevos productos</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <target-products></target-products>
        <promotions></promotions>
        <benefits></benefits>
    </div>
</template>
<style>
</style>
<script>

    import Promotions from '/src/components/Promotions'
    import TargetProducts from '/src/components/TargetProducts'
    import Benefits from '/src/components/Benefits'

    export default {

        name: 'Home',
        components: {
            Promotions,
            TargetProducts,
            Benefits,
        }
    }
</script>
