<template>
    <div class="options">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="options-all">
                        <div class="option1-box">
                            <router-link to="/promociones" class="options-items">PROMOCIONES</router-link>
                        </div>
                        <div class="option1-box">
                            <router-link to="/arreglos" class="options-items">ARREGLOS</router-link>
                        </div>
                        <div class="option1-box">
                            <a href="#" class="options-items">PARA ELLOS</a>
                        </div>
                        <div class="option1-box">
                            <a href="#" class="options-items">COMPLEMENTOS</a>
                        </div>
                        <div class="option1-box">
                            <a href="#" class="options-items">FECHAS ESPECIALES</a>
                        </div>
                        <div class="option1-box">
                            <a href="#" class="options-items">HOME & DECO</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                active: false
            }
        },
        methods: {
            activeNav: function () {

            }
        },
    }
</script>
