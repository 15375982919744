<template>
    <div>
        <preparative></preparative>
        <target-products></target-products>
    </div>
</template>
<script>

    import Preparative from '/src/components/Preparative'
    import TargetProducts from '/src/components/TargetProducts'

    export default {

        name: 'Preparations',
        components: {
            Preparative,
            TargetProducts
        }
    }
</script>
