var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-id"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"products-id-dates-box"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"products-id-amount-box-all"},[_c('div',{staticClass:"products-id-amount-box"},[_c('div',{staticClass:"products-amount-picture"},[_c('div',{staticClass:"products-id-amount-input-number0",on:{"click":_vm.decreaseAmount}},[_vm._v("-")]),_c('input',{staticClass:"products-id-amount-input-number2",attrs:{"type":"number","id":"quantity","name":"","min":"1","max":"20","disabled":"disabled"},domProps:{"value":_vm.OrderQuantify}}),_c('div',{staticClass:"products-id-amount-input-number1",on:{"click":_vm.increaseAmount}},[_vm._v("+")])]),_c('div',[_c('router-link',{staticClass:"btn btn-danger btn-pink1",attrs:{"to":"/contacto"}},[_c('div',[_c('img',{staticClass:"icon-trolley",attrs:{"src":require("@/assets/images/trolley-white.png"),"alt":""}})]),_vm._v(" Añadir al carrito ")])],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-1 col-2"},[_c('div',[_c('div',{staticClass:"products-id-image-box"},[_c('img',{staticClass:"products-id-image",attrs:{"src":require("@/assets/images/image-7.png"),"alt":""}})]),_c('div',{staticClass:"products-id-image-box"},[_c('img',{staticClass:"products-id-image",attrs:{"src":require("@/assets/images/image-9.png"),"alt":""}})]),_c('div',{staticClass:"products-id-image-box"},[_c('img',{staticClass:"products-id-image",attrs:{"src":require("@/assets/images/image-8.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-7 col-10"},[_c('div',{staticClass:"products-id-image-great"},[_c('div',{staticClass:"products-id-image-picture"},[_c('img',{staticClass:"products-id-image",attrs:{"src":require("@/assets/images/flowers-image.png"),"alt":""}})]),_c('div',{staticClass:"target-head-picture"},[_c('div',{staticClass:"target-box-favorite-box"},[_c('img',{staticClass:"target-head-image",attrs:{"src":require("@/assets/images/corazon-white.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-id-categories-picture"},[_c('p',[_vm._v("Inicio")]),_c('p',{staticClass:"products-id-paragraph-arrow-right"},[_c('i',{staticClass:"fas fa-angle-right products-id-icon-right"})]),_c('p',[_vm._v("Categoría 1")]),_c('p',{staticClass:"products-id-paragraph-arrow-right"},[_c('i',{staticClass:"fas fa-angle-right products-id-icon-right"})]),_c('p',{staticClass:"step-select-style"},[_vm._v("Sub categoría 1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-id-title-product"},[_c('h4',[_vm._v("Corazón primaveral")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-id-price-previous-box"},[_c('p',{staticClass:"products-id-price-current"},[_vm._v("S/ 119.99")]),_c('p',{staticClass:"products-id-price-previous"},[_vm._v("S/ 200.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-id-description-box"},[_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor lobortis mauris potenti auctor. Porttitor etiam sed at tempus platea amet eu nulla. Velit ipsum sociis porta habitant egestas interdum tincidunt habitasse. Morbi id commodo molestie neque sit adipiscing natoque tellus.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-id-stock-box"},[_c('p',[_vm._v("Stock: 10")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-id-free-shipping-box"},[_c('div',{staticClass:"products-id-free-shipping-image-picture"},[_c('img',{attrs:{"src":require("@/assets/images/truck.png"),"alt":""}})]),_c('p',{staticClass:"products-id-free-shipping-text"},[_vm._v("Envío gratis")])])
}]

export { render, staticRenderFns }